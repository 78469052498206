import React, { useState, useEffect, useContext } from "react";
import DataTable from "react-data-table-component";
import SocketContext from "../../context/SocketContext";
import { Modal } from "react-bootstrap";
import CurrencyFormat from 'react-currency-format';
import { useTranslation } from "react-i18next";

//import api
import { getOpenPosition, tpslUpdate, orderClose } from "../../api/tradeAction";

//import lib
import { dateTimeFormat } from "../../lib/dateTimeHelper";
import { toFixedDown, truncateDecimals } from "../../lib/roundOf"
import { toastAlert } from "../../lib/toastAlert"
import { encryptObject } from "../../lib/cryptoJS";
import { unrealizedPnL } from "../../lib/bybit";
import isEmpty from "../../lib/isEmpty";
import { IncCntObjId } from "../../lib/generalFun";



const initialFormValue = {
  isCloseModal: false,
  isLossModal: false,
  isProfitModal: false,
  takeProfit: 0,
  stopLoss: 0,
  record: {},
};

const OpenPosition = (props) => {

  const { t, i18n } = useTranslation()

  // History Table Data
  const tansaction_columns = [
    {
      name: t("DATE"),
      width: "200px",
      selector: (row) => dateTimeFormat(row?.createdAt),
    },
    {
      name: "Trade Id",
      width: "100px",
      cell: (record) => {
        // console.log(original, "--------------");
        return <>{IncCntObjId(record._id)}</>;
      },
    },
    {
      name: t("PAIR_NAME"),
      width: "120px",
      selector: (row) => row?.pairName,
    },
    {
      name: t("BUYSELL"),
      width: "100px",
      selector: (row) => <span className={row?.side == 'sell' ? "txt_red" : "txt_green"} >{row?.side == "sell" ? t("SELL") : t("BUY")}</span>,
    },
    {
      name: t("QUANTITY"),
      width: "100px",
      selector: (row) => truncateDecimals(row?.quantity, 2),
    },
    {
      name: t("LEVERAGE"),
      width: "100px",
      selector: (row) => row?.leverage,
    },
    {
      name: t("ENTRY_PRICE"),
      width: "150px",
      selector: (row) => <CurrencyFormat displayType={'text'} thousandSeparator={true} value={truncateDecimals(row?.entryPrice, 2)} />,
    },
    // {
    //   name: "Liquidation Price",
    //   width: "150px",
    //   selector: (row) => <CurrencyFormat displayType={'text'} thousandSeparator={true} value={truncateDecimals(row?.liquidationPrice, 2)} />,
    // },
    // {
    //   name: "ASK Price",
    //   width: "150px",
    //   cell: (record) => {
    //     let filter = totalpair && totalpair.find((el) => el._id.toString() == record.pairId.toString())
    //     return <CurrencyFormat displayType={'text'} thousandSeparator={true} value={truncateDecimals(filter?.askPrice, 2)} />
    //   }
    // },
    // {
    //   name: "BID Price",
    //   width: "150px",
    //   cell: (record) => {
    //     let filter = totalpair && totalpair.find((el) => el._id.toString() == record.pairId.toString())
    //     return <CurrencyFormat displayType={'text'} thousandSeparator={true} value={truncateDecimals(filter?.bidPrice, 2)} />
    //   }
    // },
    {
      name: t("CURRENT_PRICE"),
      width: "150px",
      cell: (record) => {
        let filter = totalpair && totalpair.find((el) => el._id.toString() == record.pairId.toString())
        return <CurrencyFormat displayType={'text'} thousandSeparator={true} value={truncateDecimals(filter?.markPrice, 2)} />
      }
    },
    {
      name: t("PNL_CLOSE"),
      width: "120px",
      cell: (record) => {
        // console.log(record,'filter')

        let filter = totalpair && totalpair.find((el) => el._id.toString() == record.pairId.toString())

        let pnl
        const Data = new Date(record.createdAt)
        Data.setSeconds(Data.getSeconds() + 10);
        if (new Date().getTime() >= new Date(Data).getTime() && filter) {
          let price = record?.side == "sell" ? filter?.askPrice : filter?.bidPrice;
          let pAndL = unrealizedPnL({
            entryPrice: record?.entryPrice,
            quantity: record?.quantity,
            lastPrice: price,
            buyorsell: record?.side,
          })
          // console.log(pAndL,'-------------7887' , record?.spread)
          pnl = pAndL + record?.spread * record?.quantity
        } else {
          pnl = record?.spread * record?.quantity
        }

        let color = pnl >= 0 ? "btn btn-success py-1 ml-2" : "btn btn-danger  py-1 ml-2"
        return (
          <button className={color}
            onClick={() => setOrderData({
              isCloseModal: true,
              isProfitModal: false,
              isLossModal: false,
              record: record,
            })}
          // disabled={loader}
          >
            {loader && <i className="fas fa-spinner fa-spin"></i>}
            <CurrencyFormat displayType={'text'} thousandSeparator={true} value={truncateDecimals(pnl, 2)} />
          </button >
        )
      },
    },
    {
      name: t("SL"),
      width: "150px",
      cell: (record) => {
        return (
          <button onClick={() => setOrderData({
            isLossModal: true,
            record: record,
            stopLoss: record.slPrice
          })} className="btn btn-danger  py-1 ml-2" >
            {record.isSL ? <CurrencyFormat displayType={'text'} thousandSeparator={true} value={truncateDecimals(record.slPrice, 2)} /> : "SL"}
          </button >
        )
      }
    },
    {
      name: t("TP"),
      width: "150px",
      cell: (record) => {
        return (
          <button onClick={() => setOrderData({
            isProfitModal: true,
            record: record,
            takeProfit: record.tpPrice
          })} className="btn btn-success  py-1 ml-2"  >
            {record.isTP ? <CurrencyFormat displayType={'text'} thousandSeparator={true} value={truncateDecimals(record.tpPrice, 2)} /> : "TP"}
          </button >
        )
      }
    },
  ];


  //state
  const [data, setData] = useState([])
  const [count, setCount] = useState()
  const [pageDoc, setPageDoc] = useState({ page: 1, limit: 20 });
  const [loader, setLoader] = useState(false)
  const { page, limit } = pageDoc;
  const [orderData, setOrderData] = useState(initialFormValue);
  const { isProfitModal, takeProfit, stopLoss, isLossModal, record } = orderData

  const socketContext = useContext(SocketContext);

  //props
  const { refetch, totalpair } = props


  //function
  const fetchTradeHistory = async () => {
    const { status, loading, message, result, errors } = await getOpenPosition({ page, limit })
    if (status == 'success') {
      setData(result?.data);
      setCount(result?.count)
    }
  }

  const handleCloseModal = () => {
    setOrderData({ isCloseModal: false, isLossModal: false, isProfitModal: false, record: {} });
  };

  const handleTotal = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    if (name == "takeProfit" || name == "stopLoss") {
      if (/[^0-9.]/.test(value)) return;
    }
    let formData = { ...orderData, ...{ [name]: toFixedDown(value, 8) } };
    setOrderData(formData);
    if (value) {
      setLoader(false)
    }
  };

  const handleSubmit = async () => {
    let reqData = {
      quantity: orderData?.record?.quantity,
      buyorsell: orderData?.record?.side == "buy" ? "sell" : "buy",
      pairId: orderData?.record?.pairId,
      leverage: orderData?.record?.leverage,
      newdate: new Date(),
      action: "close",
      positionId: orderData?.record?._id,
    }
    setLoader(true)
    let encryptToken = {
      token: encryptObject(reqData),
    };
    const { status, loading, message, result, errors } = await orderClose(encryptToken)
    if (status) {
      toastAlert("success", t(message), "login");
      setLoader(false)
      handleCloseModal()
    } else {
      setLoader(false)
      toastAlert("error", t(message), "login");
    }
  }


  const handleUpdate = async () => {

    let filter = totalpair && totalpair.find((el) => el._id.toString() == orderData?.record?.pairId.toString())

    if (isProfitModal) {
      if (isEmpty(takeProfit) || takeProfit === "" || takeProfit === "0") {
        toastAlert("error", t("TAKE_PROFIT_REQUIRED"), "marketOrder_tp");
        return;
      }

      if (orderData?.record?.side == "buy") {
        if (!isEmpty(takeProfit) && takeProfit < filter?.markPrice) {
          toastAlert("error", t("TAKE_PROFIT_GREATER"), "marketOrder_tp");
          return;
        }
      } else {
        if (!isEmpty(takeProfit) && takeProfit > filter?.markPrice) {
          toastAlert("error", t("TAKE_PROFIT_LESSER"), "marketOrder_tp");
          return;
        }
      }
    } else if (isLossModal) {
      if (isEmpty(stopLoss) || stopLoss === "" || stopLoss === "0") {
        toastAlert("error", t("STOP_LOSS_REQUIRED"), "marketOrder_tp");
        return;
      }

      if (orderData?.record?.side == "buy") {
        if (!isEmpty(stopLoss) && stopLoss > filter?.markPrice) {
          toastAlert("error", t("STOP_LOSS_GREATER"), "marketOrder_sl");
          return;
        }
      } else {
        if (!isEmpty(stopLoss) && stopLoss < filter?.markPrice) {
          toastAlert("error", t("STOP_LOSS_LESSER"), "marketOrder_sl");
          return;
        }
      }
    }

    let reqData = {
      positionId: orderData?.record?._id,
      isTP: isProfitModal,
      isSL: isLossModal,
      takeProfit,
      stopLoss,
    }
    setLoader(true)
    const { status, loading, message, result, errors } = await tpslUpdate(reqData)
    if (status) {
      toastAlert("success", t(message), "login");
      setLoader(false)
      handleCloseModal()
      fetchTradeHistory()
    } else {
      toastAlert("error", t(message), "login");
      setLoader(false)
    }
  }

  useEffect(() => {
    fetchTradeHistory()
    refetch()
    socketContext.socket.on("usdtPositionOrder", (result) => {
      // console.log(result, 'result')
      setData(result?.data);
      setCount(result?.count)
    });
  }, [page, limit])



  const customPaginationOptions = {
    rowsPerPageText: t('ROWS_PER_PAGE'),
    rangeSeparatorText: t('OF'),
  };

  return (
    <>
      <Modal show={orderData.isCloseModal} onHide={handleCloseModal}
        backdrop="static"
        keyboard={false}
        size="md"
        centered >
        <Modal.Header closeButton onClick={handleCloseModal}><Modal.Title><h5>{t("CLOSE_ORDER")}</h5></Modal.Title></Modal.Header>
        <Modal.Body>
          <form>
            <p>{t("CLOSE_CONFIRM")}.</p>
            <button type="button" className="btn btn-danger btn-outline text-uppercase py-1 ml-2" onClick={handleCloseModal} >{t('CANCEL')}</button>{" "}
            {
              loader ? <button type="button" className="btn btn-success btn-outline text-uppercase py-1 ml-2" disabled={true} >{t("LOADING")}...</button> :
                <button type="button" className="btn btn-success btn-outline text-uppercase py-1 ml-2" onClick={handleSubmit} >{t('OK')}</button>}
          </form>
        </Modal.Body>
      </Modal>
      <Modal show={orderData.isProfitModal} onHide={handleCloseModal}
        backdrop="static"
        keyboard={false}
        size="md"
        centered >
        <Modal.Header closeButton onClick={handleCloseModal}><Modal.Title><h5>{t("TAKE_PROFIT")}</h5></Modal.Title></Modal.Header>
        <Modal.Body>
          <form>
            <div class="form-group">
              <div className="d-flex justify-content-between gap-10 flex-wrap">
                <label for="amount" class="form-label">
                  {t("TP_PRICE")}
                </label>
              </div>
              <div className="input-group">
                <input
                  type="text"
                  className="form-control"
                  name="takeProfit"
                  value={takeProfit}
                  onChange={handleTotal}
                  placeholder={t("TAKE_PROFIT_PLACEHOLDER")}
                />
              </div>
            </div>
            <br />
            <button type="button" className="btn btn-danger btn-outline text-uppercase py-1 ml-2" onClick={handleCloseModal} >{t('CANCEL')}</button>{" "}
            {
              loader ? <button type="button" className="btn btn-success btn-outline text-uppercase py-1 ml-2" disabled={true} >{t("LOADING")}...</button> :
                <button type="button" className="btn btn-success btn-outline text-uppercase py-1 ml-2" onClick={handleUpdate} >{t('OK')}</button>}
          </form>
        </Modal.Body>
      </Modal>
      <Modal show={orderData.isLossModal} onHide={handleCloseModal}
        backdrop="static"
        keyboard={false}
        size="md"
        centered >
        <Modal.Header closeButton onClick={handleCloseModal}><Modal.Title><h5>{t("STOP_LOSS")}</h5></Modal.Title></Modal.Header>
        <Modal.Body>
          <form>
            <div class="form-group">
              <div className="d-flex justify-content-between gap-10 flex-wrap">
                <label for="amount" class="form-label">
                  {t("SP_PRICE")}
                </label>
              </div>
              <div className="input-group">
                <input
                  type="text"
                  className="form-control"
                  name="stopLoss"
                  value={stopLoss}
                  onChange={handleTotal}
                  placeholder={t("STOP_LOSS_PLACEHOLDER")}
                />
              </div>
            </div>
            <br />
            <button type="button" className="btn btn-danger btn-outline text-uppercase py-1 ml-2" onClick={handleCloseModal} >{t('CANCEL')}</button>{" "}
            {
              loader ? <button type="button" className="btn btn-success btn-outline text-uppercase py-1 ml-2" disabled={true} >{t("LOADING")}...</button> :
                <button type="button" className="btn btn-success btn-outline text-uppercase py-1 ml-2" onClick={handleUpdate} >{t('OK')}</button>}
          </form>
        </Modal.Body>
      </Modal>
      <div className="row">
        <div className="col-xl-12">
          <div className="dash_box">
            <div className="primary_datatable">
              <DataTable
                columns={tansaction_columns}
                data={data}
                pagination
                paginationServer
                paginationTotalRows={count}
                paginationPerPage={limit}
                paginationRowsPerPageOptions={[20, 30, 40, 50]}
                onChangePage={(page) =>
                  setPageDoc({ ...pageDoc, ...{ page: page } })
                }
                onChangeRowsPerPage={(limit) =>
                  setPageDoc({ ...pageDoc, ...{ page: 1, limit: limit } })
                }
                noDataComponent={t('THERE_NO_RECORD')}
                paginationComponentOptions={customPaginationOptions}

              />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default OpenPosition;