import isEmpty from "../../lib/isEmpty";
export const WithdrawValidation = (value, t) => {
    let errors = {};

    if (isEmpty(value.receiverAddress)) {
        errors.receiverAddress = t("RECEIVER_ADDRESS_REQUIRED")
    }

    if (isEmpty(value.amount)) {
        errors.amount = t("AMOUNT_REQUIRED")
    } else if (isNaN(value.amount)) {
        errors.amount = t("VALID_AMOUNT")
    } else if (parseFloat(value.minWithdraw) > parseFloat(value.amount)) {
        errors.amount = t('MINIMUM_WITHDRAW_ERROR', { AMOUNT: value.minWithdraw });
    } else if (parseFloat(value.maxWithdraw) < parseFloat(value.amount)) {
        errors.amount = t('WITHDRAW_MAXAMOUNT_ERROR', { AMOUNT: value.maxWithdraw });
    }


    // if (isEmpty(value.otpStatus.toString())) {
    //     errors.otpStatus = t("OTPSTATUS_REQUIRED");
    // } else if (![true, false].includes(value.otpStatus)) {
    //     errors.otpStatus = t("INVALID_OTPSTATUS");
    // } else if (value.otpStatus == true && isEmpty(value.otp)) {
    //     errors.otp = t("OTP_REQUIRED");
    // }

    return errors;
}
